import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const Subfooter = ({ content }) => {
  return (
    <div className="subfooter">
      <div className="subfooter__content">{content}</div>
    </div>
  );
};

Subfooter.defaultProps = {
  content: null,
};

Subfooter.propTypes = {
  /** The text content to display in the subfooter component */
  content: PropTypes.string.isRequired,
};

export default Subfooter;
