import React from 'react';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import { Row } from '../../components/Grid';
import Resource from '../../components/Resource';
import Subfooter from '../../components/Subfooter';
import Hero from '../../components/Hero';
import '../../styles/styles.scss';

const heroMultiImages = [
  {
    image: '/header-lead-College-Career-Readiness-01@2x.jpg',
    title: 'College and Career Readiness',
    subtext: 'Inspiring the next generation of innovators',
    text:
      'Discover ways you can use biotech to support academic success and hone-in on a rewarding career path in a growing STEM industry.',
  },
  {
    image: '/header-lead-College-Career-Readiness-02@2x.jpg',
  },
  {
    image: '/header-lead-College-Career-Readiness-03@2x.jpg',
  },
];

const IndexPage = () => (
  <Layout title="College and Career Readiness">
    <Hero className="pb-0" mediaSource={heroMultiImages} color={'blue'} />
    <Section>
      {/* <Row>
        <Resource
          horizontal
          className={'resources--horizontal'}
          withBorder={false}
          asCard
          img="img-college-career-readiness-futurelab-network.png"
          title="Futurelab+ Network"
          description="The Futurelab+ Network connects educators and biotechnology professionals—including Genentech employee volunteers—in pursuit of advancing biotechnology education for all students. Attend live virtual events to learn more about biotech careers and pathways. Access and share resources to support instruction. Work collaboratively with other educators to address pressing problems of practice."
          actions={[
            {
              label: 'JOIN THE COMMUNITY',
              type: 'external',
              url: 'https://futurelabplus-network.gene.com/v2',
            },
          ]}
        />
      </Row> */}
      <Row>
        <Resource
          horizontal
          className={'resources--horizontal'}
          withBorder={false}
          asCard
          img="img-college-career-readiness-02-many-faces@2x.jpg"
          title="The Many Faces of Biotechnology"
          description="Meet real-world professionals working in a wide variety of exciting biotech careers with a series of interactive Career Profiles. Find out how these subject matter experts discovered their passion for problem-solving and start developing your own career goals."
          actions={[
            {
              label: 'Watch biotechnology experts share their career story',
              type: 'link',
              path: '/college-career-readiness/career-profiles/',
            },
          ]}
        />
      </Row>
      <Row>
        <Resource
          horizontal
          className={'resources--horizontal'}
          withBorder={false}
          asCard
          img="img-college-career-readiness-03-what-biotech-career-aligns@2x.jpg"
          title="What Biotech Career Aligns with Your Interests?"
          description="Find out what biotech career path aligns with your interests using an interactive Career Finder tool. Answer a series of simple questions to discover what biotech career is right for you and learn more about a wide variety of career paths from Research and Development to Food, Animal, & Environmental Science to Manufacturing."
          actions={[
            {
              label: 'Explore Careers',
              type: 'link',
              path: '/college-career-readiness/career-finder/',
            },
          ]}
        />
      </Row>
      {/* <Row>
        <Resource
          horizontal
          className={'resources--horizontal'}
          withBorder={false}
          asCard
          img="img-college-career-readiness-04-interact-with-biotech@2x.jpg"
          title="Getting Started with Biotech"
          description="Take control of your explorations into biotechnology with these self-guided interactives. You can explore real-world examples of advances in biotech that are revolutionizing medicine and improving lives globally."
          actions={[
            {
              label: 'Interact with Biotechnology',
              type: 'link',
              path: '/classroom-instruction/student-interactives',
            },
          ]}
        />
      </Row> */}
    </Section>
    <Section className="pb-0">
      <Subfooter content="Biotech is revolutionizing every aspect of life, from advances in medical diagnostics and drug development to improvements in agricultural and food products." />
    </Section>
  </Layout>
);

export default IndexPage;
